import React from 'react';

const WaveTop = ({
    classNamePath
}) => {
  return (
    <div className="relative">
      <svg
        className="divider-top divider-default w-full h-[150px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path
          className={classNamePath} // Ganti 'text-gray-300' dengan warna yang kamu inginkan
          d="M0,32L80,32C160,32,320,32,480,69.3C640,107,800,181,960,213.3C1120,245,1280,235,1360,229.3L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        />
      </svg>
    </div>
  );
};

export default WaveTop;
