import React, { useState, useEffect } from 'react';

const InfoPanel = ({ isDarkMode, title, id, heading, context,context0, context1, context2, darkBg, lightBg }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`w-full h-fit pt-12 py-12 flex flex-col ${isDarkMode ? `${darkBg}` : `${lightBg}`} justify-center`}>
      <div className="flex justify-center pt-12">
        <h1 className={`font-bold mb-4 pt-4 ${isSmallScreen ? 'text-6xl text-center' : 'text-8xl'} ${isDarkMode ? '' : 'opacity-75'}`}>
          {title}
        </h1>
      </div>
      {/* grid-cols-1 md:grid-cols-2 */}
      <div id={id} className="section grid gap-8 px-4 py-12 text-lg items-start justify-center">
        <div className={`content flex flex-col ${isSmallScreen? '' : ' mx-44'}`}>
          <h1 className={`font-semibold pb-6 ${isSmallScreen ? 'text-lg' : 'text-3xl'}`}>
            {heading}
          </h1>
          <div className={`flex flex-col gap-6 text-lg ${isSmallScreen ? 'text-sm' : ''}`}>
          <p className={`text-lg ${isSmallScreen ? 'text-sm' : ''}`}>
            {context}
          </p>
          <p className={`text-lg ${isSmallScreen ? 'text-sm' : ''}`}>
            {context0}
          </p>
          <p className={`text-lg ${isSmallScreen ? 'text-sm' : ''}`}>
            {context1}
          </p>
          <p className={`text-lg ${isSmallScreen ? 'text-sm' : ''}`}>
            {context2}
          </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
