// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
    position: relative;
    min-width: auto;
    overflow: hidden;
    margin: 5px;
    height: 400px;
}

/* .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
} */

.carousel-item {
    min-width: 100%;
    gap: 24px;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-inner > div {
    flex-shrink: 0;
    width: 100%;
}

.carousel-item img {
    width: 100%;
    height: auto;
    border-top-right-radius: 20px ;
    border-bottom-left-radius: 20px;
}


.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.carousel-item {
    transition: opacity 0.5s ease;
    opacity: 0;
}

.carousel-item.active {
    opacity: 1; /* Gambar aktif terlihat */
}

.title h1 {
    font-weight: 600;
    font-size: 1.5rem;
}
.title p {
    font-weight: 500;
    font-size: 1 rem;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/Carousel/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,aAAa;AACjB;;AAEA;;;GAGG;;AAEH;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,+BAA+B;AACnC;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,+BAA+B;AACnC;;;AAGA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,UAAU,EAAE,0BAA0B;AAC1C;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".carousel {\n    position: relative;\n    min-width: auto;\n    overflow: hidden;\n    margin: 5px;\n    height: 400px;\n}\n\n/* .carousel-inner {\n    display: flex;\n    transition: transform 0.5s ease-in-out;\n} */\n\n.carousel-item {\n    min-width: 100%;\n    gap: 24px;\n}\n\n.carousel-inner {\n    display: flex;\n    transition: transform 0.5s ease;\n}\n\n.carousel-inner > div {\n    flex-shrink: 0;\n    width: 100%;\n}\n\n.carousel-item img {\n    width: 100%;\n    height: auto;\n    border-top-right-radius: 20px ;\n    border-bottom-left-radius: 20px;\n}\n\n\n.prev {\n    left: 10px;\n}\n\n.next {\n    right: 10px;\n}\n\n.carousel-item {\n    transition: opacity 0.5s ease;\n    opacity: 0;\n}\n\n.carousel-item.active {\n    opacity: 1; /* Gambar aktif terlihat */\n}\n\n.title h1 {\n    font-weight: 600;\n    font-size: 1.5rem;\n}\n.title p {\n    font-weight: 500;\n    font-size: 1 rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
