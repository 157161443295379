import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';

const TimelineItem = ({ title, description, expanded, onChange, isLast, isDarkMode }) => {
  return (
    <div className="flex mb-4 w-full">
      <div className="relative w-10">
        <div className={`absolute w-3 h-3 rounded-full top-0 left-1/2 transform -translate-x-1/2 ${isDarkMode ? 'bg-white' : 'bg-black'}`} />
        {!isLast && (
          <div className={`h-full border-l-2  absolute left-1/2 top-3 ${expanded ? 'top-5' : 'top-0'} ${isDarkMode ? 'bg-white' : 'border-black'}`} />
        )}
      </div>
      <div className={`flex-1 border-b-2 ${expanded ? '' : ''} ${isDarkMode ? 'border-white' : 'border-black'}`}>
        <div
          className="flex justify-between items-center cursor-pointer p-4"
          onClick={onChange}
        >
          <div>
            <h5 className="text-xl">{title}</h5>
          </div>
          <div className="text-gray-500">
            {expanded ? (
              <ExpandLess sx={{ color: isDarkMode ? "white" : "black" }} />
            ) : (
              <ExpandMore sx={{ color: isDarkMode ? "white" : "black" }} />
            )}
          </div>
        </div>
        <div className={`overflow-hidden transition-all duration-500 ease-in-out ${expanded ? 'max-h-auto pb-4' : 'max-h-0 pb-0'}`}>
          <p className={`${expanded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 ease-in-out px-4 pb-3`}>{description}</p>
        </div>
      </div>
    </div>
  );
};

const Timeline = ({ isDarkMode }) => {
  const events = [
    { title: 'Memberdayakan Inovator Masa Depan', description: 'Bergabunglah dengan kami dalam membentuk generasi pemikir dan pencipta. Program kami mengintegrasikan teknologi mutakhir dengan keunggulan pendidikan, memastikan siswa siap menghadapi tantangan masa depan.' },
    { title: 'Menjembatani Pengetahuan dan Teknologi', description: 'Kami menggabungkan ketelitian pendidikan dengan kemajuan teknologi. Kursus kami dirancang untuk memberikan keterampilan praktis dan pengetahuan yang relevan dalam lanskap digital yang bergerak cepat saat ini.' },
    { title: 'Komitmen Terhadap Pembelajaran Sepanjang Hayat', description: 'Di inti kami, kami percaya pada pertumbuhan berkelanjutan. Solusi pembelajaran inovatif kami menciptakan lingkungan di mana rasa ingin tahu berkembang, mendorong para pelajar untuk menjelajah dan unggul sepanjang hidup mereka.' },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionChange = (index) => () => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <div className="max-w-4xl mx-auto p-4 w-full">
      {events.map((event, index) => (
        <TimelineItem
          key={index}
          title={event.title}
          description={event.description}
          expanded={expandedIndex === index}
          onChange={handleAccordionChange(index)}
          isLast={index === events.length - 1}
          isDarkMode={isDarkMode}
        />
      ))}
    </div>
  );
};

export default Timeline;
