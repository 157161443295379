import React, { useState } from 'react';
import './style.css'; // Tambahkan CSS yang diperlukan
import illustrasi1 from '../../../assets/illust1.png';
import illustrasi2 from '../../../assets/illust2.png';
import illustrasi3 from '../../../assets/illust3.png';
import illustrasi4 from '../../../assets/illust4.png';
import illustrasi5 from '../../../assets/illust5.png';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const images = [illustrasi1, illustrasi2, illustrasi3, illustrasi4, illustrasi5];

const products = [
    {
        name: "Produk 1",
        details: "Detail singkat produk 1",
        link: "link_ke_produk_1"
    },
    {
        name: "Produk 2",
        details: "Detail singkat produk 2",
        link: "link_ke_produk_2"
    },
    {
        name: "Produk 3",
        details: "Detail singkat produk 3",
        link: "link_ke_produk_3"
    },
    {
        name: "Produk 4",
        details: "Detail singkat produk 4",
        link: "link_ke_produk_4"
    },
    {
        name: "Produk 5",
        details: "Detail singkat produk 5",
        link: "link_ke_produk_5"
    },
];

const Carousel = ({ isDarkMode, isMobile }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const nextSlide = () => {
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            setIsTransitioning(false);
        }, 100);
    };

    const prevSlide = () => {
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
            setIsTransitioning(false);
        }, 100);
    };

    return (
        <div className="w-full h-fit pt-24 py-12 flex flex-col justify-center">
            {!isMobile ? (
                <div className="grid grid-cols-1 md:grid-cols-5 gap-12">
                    <div className="carousel flex md:col-span-3 relative overflow-hidden justify-center">
                        <div className="carousel-inner flex items-center justify-center transition-all duration-100 ease-in-out">
                            {images.map((image, index) => (
                                <div
                                    key={index}
                                    className={`w-full transition-transform duration-100 ease-in-out 
                                        ${isTransitioning && index === currentIndex 
                                            ? "scale-75 opacity-100 z-10" 
                                            : index === currentIndex 
                                            ? "scale-100 opacity-100 z-10" 
                                            : "scale-50 opacity-0 z-0"}`}
                                    style={{
                                        position: index === currentIndex ? "relative" : "absolute",
                                        transition: 'transform 0.3s ease, opacity 0.1s ease',
                                    }}
                                >
                                    <img
                                        src={image}
                                        alt={`Gambar ${index + 1}`}
                                        className="w-full max-w-2xl h-auto mx-auto"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col w-full justify-center md:col-span-2">
                        <div className="title flex flex-col gap-6 w-full ">
                            <ul>
                                <li>
                                    <div>
                                        <h1 className="text-lg font-bold">{products[currentIndex].name}</h1>
                                        <p className="text-sm">{products[currentIndex].link}</p>
                                    </div>
                                </li>
                                <li>
                                    <h1 className="text-lg font-bold">Detail</h1>
                                    <p className="text-sm">{products[currentIndex].details}</p>
                                </li>
                                <li>
                                    <h1 className="text-lg font-bold">Link</h1>
                                    <p className="text-sm">{products[currentIndex].link}</p>
                                </li>
                            </ul>
                        </div>

                        <div className="flex pt-6 gap-3 justify-center md:justify-start">
                            <IconButton onClick={prevSlide} className="rounded-full">
                                <ArrowLeft sx={{ fontSize: 50, border: 1, borderRadius: '100%', color: isDarkMode ? "white" : "black" }} />
                            </IconButton>
                            <IconButton onClick={nextSlide} className="rounded-full">
                                <ArrowRight sx={{ fontSize: 50, border: 1, borderRadius: '100%', color: isDarkMode ? "white" : "black" }} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-6 mt-8">
                    <div className="carousel relative overflow-hidden flex justify-center">
                        <div className="carousel-inner flex items-center justify-center">
                            {images.map((image, index) => (
                                <div
                                    key={index}
                                    className={`w-full transition-transform duration-100 ease-in-out 
                                        ${isTransitioning && index === currentIndex 
                                            ? "scale-75 opacity-100 z-10" 
                                            : index === currentIndex 
                                            ? "scale-100 opacity-100 z-10" 
                                            : "scale-50 opacity-0 z-0"}`}
                                    style={{
                                        position: index === currentIndex ? "relative" : "absolute",
                                        transition: 'transform 0.3s ease, opacity 0.1s ease',
                                    }}
                                >
                                    <img
                                        src={image}
                                        alt={`Gambar ${index + 1}`}
                                        className="w-full max-w-2xl h-auto mx-auto"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col text-center">
                        <h1 className="text-lg font-bold">{products[currentIndex].name}</h1>
                        <p className="text-sm">{products[currentIndex].details}</p>
                        <p className="text-sm">{products[currentIndex].link}</p>
                    </div>

                    <div className="flex justify-center gap-3 pt-4">
                        <IconButton onClick={prevSlide} className="rounded-full">
                            <ArrowLeft sx={{ fontSize: 40, border: 1, borderRadius: '100%', color: isDarkMode ? "white" : "black" }} />
                        </IconButton>
                        <IconButton onClick={nextSlide} className="rounded-full">
                            <ArrowRight sx={{ fontSize: 40, border: 1, borderRadius: '100%', color: isDarkMode ? "white" : "black" }} />
                        </IconButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Carousel;

