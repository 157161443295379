import React from 'react';

const WaveBottom = ({
    classNamePath
}) => {
    return (
        <div className="relative">
            <svg
                className="divider-top divider-default w-full h-[150px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                preserveAspectRatio="none"
            >
                <path
                    className={classNamePath}
                    d="M0,32L80,32C160,32,320,32,480,69.3C640,107,800,181,960,213.3C1120,245,1280,235,1360,229.3L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                />
            </svg>
        </div>
    );
};

export default WaveBottom;
